import React, { useEffect, useState } from "react";
import './liverpool.css'
import Assets from "../../assets/assets";
import Whatapps from '../../assets/bot/whatapp.svg'
import axios from "axios";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FiPhoneCall } from "react-icons/fi";
import { Helmet } from "react-helmet";

const Livepool = () => {

    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phone: "",
        careerInterest: ""
    });

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");
    const [download, setDownload] = useState(false)

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        // Validate Full Name
        if (!formData.fullName) {
            isValid = false;
            formErrors["fullName"] = "Please enter your full name.";
        }

        // Validate Email
        if (!formData.email) {
            isValid = false;
            formErrors["email"] = "Please enter your email address.";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            isValid = false;
            formErrors["email"] = "Email address is invalid.";
        }

        // Validate Phone Number
        if (!formData.phone) {
            isValid = false;
            formErrors["phone"] = "Please enter your phone number.";
        } else if (!/^\d{10}$/.test(formData.phone)) {
            isValid = false;
            formErrors["phone"] = "Phone number is invalid. Must be 10 digits.";
        }

        // Validate Career Interest
        if (!formData.careerInterest) {
            isValid = false;
            formErrors["careerInterest"] = "Please enter your career interest.";
        }

        setErrors(formErrors);
        return isValid;
    };


    const handleScroll = () => {
        const target = document.getElementById('scrollTarget');
        target.scrollIntoView({ behavior: 'smooth' });
    };

    async function checkForDuplicateLead(email, phone) {
        const webhookUrl = 'https://leadsense.bitrix24.in/rest/31/j87gms00216fmp2o/crm.lead.list.json';
        // Prepare query parameters
        const queryParams = new URLSearchParams();
        if (email) {
            queryParams.append('filter[EMAIL]', email);
        }
        if (phone) {
            queryParams.append('filter[PHONE]', phone);
        }
        queryParams.append('select[]', 'ID'); // Add ID to the select array
        const response = await fetch(`${webhookUrl}?${queryParams.toString()}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        return result.result.length > 0 ? result.result[0].ID : null;
    }

    async function addLeadToBitrix24(name, email, phone, course, soursevalue) {
        const existingLeadId = await checkForDuplicateLead(email, phone);
        // console.log(existingLeadId);
        if (existingLeadId) {
            alert('Your email or phone number is already registered in our system. Our team will be in touch with you shortly..');
            setFormData({
                fullName: "",
                email: "",
                phone: "",
                careerInterest: ""
            })
            setLoading(false);
            return;
        } else {

            const webhookUrl = 'https://leadsense.bitrix24.in/rest/31/j87gms00216fmp2o/crm.lead.add.json';
            const data = {
                fields: {
                    TITLE: 'https://iop.liba.edu/',
                    NAME: name,
                    EMAIL: [{ VALUE: email, VALUE_TYPE: 'WORK' }],
                    PHONE: [{ VALUE: phone, VALUE_TYPE: 'WORK' }],
                    SOURCE_ID: "WEB",
                    UF_CRM_1637687988896: "2361", // Adding source as source description

                }
            };
            try {
                const response = await fetch(webhookUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const result = await response.json();
                if (result.error) {
                    alert('Error: ' + result.error_description);
                } else {
                    // hideLoader();
                    setResponseMessage("Form submitted successfully!");
                    setFormData({
                        fullName: "",
                        email: "",
                        phone: "",
                        careerInterest: ""
                    })
                    setLoading(false);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                alert('Error: ' + error);
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true);

            const url = "https://api.123admissions.com/api/v1/formall";

            let data = new FormData();
            data.append("name", formData.fullName);
            data.append("email", formData.email);
            data.append("contact", formData.phone);
            data.append("cou", formData.careerInterest);
            data.append("id", 'uma');

            try {
                const response = await fetch(url, {
                    method: "POST",
                    body: data
                });

                const result = await response.json();

                if (response.ok) {

                } else {
                    setResponseMessage(`Error: ${result.message}`);
                }

            } catch (error) {
                setResponseMessage("Failed to submit the form. Please try again.");
                console.error("Error:", error);
            } finally {
                // setLoading(false);
                addLeadToBitrix24(formData.fullName, formData?.email, formData.phone)
            }
        }
    };

    const [openSections, setOpenSections] = useState([false, false, false, false]);

    const toggleSection = (index) => {
        setOpenSections(prevSections =>
            prevSections.map((isOpen, i) => (i === index ? !isOpen : isOpen))
        );
    };
    const openWhatsApp = () => {
        const phoneNumber = '919597725388'; // Replace with the phone number you want to open
        const whatsappUrl = `https://wa.me/${phoneNumber}`;
        window.open(whatsappUrl, '_blank');
    };
    const openCall = () => {
        const phoneNumber = '919597725388'; // Replace with the phone number you want to call
        const callUrl = `tel:${phoneNumber}`;
        window.open(callUrl);
    };
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 668, // Adjust this value as per your design for mobile devices
                settings: {
                    slidesToShow: 1, // Number of slides to show on mobile
                },
            },
        ],
    };
    return (
        <>
            <Helmet>
                <title>Liverpool University</title>
                <meta name="description" content="This is the homepage of my React app, showcasing modern web solutions." />
                <meta name="keywords" content="React, Helmet, SEO, JavaScript, Web Development, Frontend" />
                <meta name="author" content="123admissions" />

                {/* Open Graph tags for social media previews */}
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Liverpool University | Home" />
                <meta property="og:description" content="Explore our React app homepage with insights on modern web solutions." />
                <meta property="og:image" content="https://123admissions.com/static/media/liverpoolLogo.3237ad4f1a71e002fa05.webp" />
                <meta property="og:url" content="https://123admissions.com/" />
                <meta property="og:site_name" content="Liverpool University" />

                {/* Twitter Card tags for social media previews */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Liverpool University | Home" />
                <meta name="twitter:description" content="Explore our React app homepage with insights on modern web solutions." />
                <meta name="twitter:image" content="https://123admissions.com/static/media/liverpoolLogo.3237ad4f1a71e002fa05.webp" />
                <meta name="twitter:site" content="@your_twitter_handle" />
                <meta name="twitter:creator" content="@your_twitter_handle" />

                {/* General viewport and SEO tags */}
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="robots" content="index, follow" />
                <meta name="googlebot" content="index, follow" />

                {/* Canonical link */}
                <link rel="canonical" href="https://123admissions.com/" />

                {/* Favicon and Apple Touch Icons */}
                <link rel="icon" href="https://123admissions.com/favicon.ico" type="image/x-icon" />
                <link rel="apple-touch-icon" href="https://123admissions.com/apple-touch-icon.png" sizes="180x180" />
                <link rel="icon" href="https://123admissions.com/favicon-32x32.png" sizes="32x32" type="image/png" />
                <link rel="icon" href="https://123admissions.com/favicon-16x16.png" sizes="16x16" type="image/png" />

                {/* Compatibility and theme color */}
                <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="theme-color" content="#ffffff" />
            </Helmet>
            <div className="liverpool">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="headers">
                            <h3>Check our latest programme.</h3>
                            <button onClick={handleScroll}>
                                ENQUIRE NOW →
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-5">
                        <div className="bodys">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="pathners">
                                        {/* <img src={Assets.Logo} className="logo" ></img> */}
                                        <img src={Assets.Livepoollogo} className="logo"></img>
                                    </div>
                                    <h5>Master of Business
                                        Administration(MBA) </h5>
                                    <h2>Liverpool University, Powered by</h2>
                                    <h3>India’s Leading Ed-Tech Platform, 123Admissions</h3>
                                    <small className="fontchange">Your education, Your way - just a click away!</small>

                                    <div className="underline">
                                    </div>
                                    <button>Learn from Anywhere</button>
                                </div>
                                <div className="col-lg-6" >
                                    <div className="campaignimg">
                                        <Slider {...settings}>
                                            <div>
                                                <img src={Assets?.Liverpool} alt="Slide 1" style={{ width: "100%" }} />
                                            </div>
                                            <div>
                                                <img src={Assets?.Liverpool1} alt="Slide 1" style={{ width: "100%" }} />
                                            </div>
                                            <div>
                                                <img src={Assets?.Liverpool2} alt="Slide 1" style={{ width: "100%" }} />
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12" id="scrollTarget">
                        <div className="enquery">
                            <div className="text">
                                <h5>Let's Connect</h5>
                            </div>
                            <div className="form-outer"  >
                                <div className="form">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="text">
                                                    <h5>Kindly fill in the details so our experts can reach out to you.</h5>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 mt-3">
                                                <input
                                                    className="form-control"
                                                    name="fullName"
                                                    value={formData.fullName}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter Your Full Name"
                                                />
                                                {errors.fullName && <small className="text-danger">{errors.fullName}</small>}
                                            </div>
                                            <div className="col-lg-12 mt-3">
                                                <input
                                                    className="form-control"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter Your Email Address"
                                                />
                                                {errors.email && <small className="text-danger">{errors.email}</small>}
                                            </div>
                                            <div className="col-lg-12 mt-3">
                                                <input
                                                    className="form-control"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter Your Phone Number"
                                                />
                                                {errors.phone && <small className="text-danger">{errors.phone}</small>}
                                            </div>
                                            <div className="col-lg-12 mt-3">
                                                <input
                                                    className="form-control"
                                                    name="careerInterest"
                                                    value={formData.careerInterest}
                                                    onChange={handleInputChange}
                                                    placeholder="What would you like to pursue for career growth?"
                                                />
                                                {errors.careerInterest && <small className="text-danger">{errors.careerInterest}</small>}
                                            </div>
                                            <div className="col-lg-12 mt-5">
                                                <div className="text-center">
                                                    <button type="submit" className="btn btn-primary" disabled={loading}>
                                                        {loading ? "Submitting..." : "Enquire Now"}
                                                    </button>
                                                </div>
                                                {responseMessage && <div className="mt-3 text-center">{responseMessage}</div>}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-5 livepoolpara">
                        <div className="container">
                            <h4>MBA from
                                Liverpool Business School</h4>
                            <p>Welcome to the MBA program from Liverpool Business School. We are delighted to have
                                you as an addition to our 100+ year legacy. Our MBA program aims to respond to the
                                evidence-based need for trained senior managers by developing confident, competent,
                                forward-thinking leaders who can balance strategic thinking with excellent operational
                                focus. </p>
                            <p>This is a distinctive MBA Program, which is intellectually-challenging, insightful and
                                focused on improving practice in the workplace across all areas of management,
                                leadership, and the functions of business. The program also offers electives so learners
                                can deep dive into a functional area of their choice</p>
                            <p>
                                With upGrad, we promise to equip you with the perfect mix of business acumen and
                                technical capabilities to help you achieve your career goals. I look forward to welcoming
                                you to Liverpool Business School and the progressive learning and development
                                experiences of our MBA.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="text-center">
                                        <h2>Specializations</h2>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-4">
                                    <div className="specializations">
                                        <div>
                                            <div class="specialization">
                                                <h5>1. Business Analytics</h5>
                                            </div>

                                            <div class="specialization">
                                                <h5>3. Marketing</h5>
                                            </div>

                                            <div class="specialization">
                                                <h5>5. Human Resource Management</h5>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-6 mt-4">
                                    <div className="specializations">
                                        <div>   <div class="specialization">
                                            <h5>2. Finance</h5>
                                        </div>
                                            <div class="specialization">
                                                <h5>4. Leadership</h5>
                                            </div>
                                            <div class="specialization">
                                                <h5>6. Operations and Supply Chain Management</h5>
                                            </div></div>
                                    </div>

                                </div>
                                <div className="col-lg-12 mt-4  mb-5">
                                    <div className="text-center">
                                        <button className="btn btn-primary">For more details download brochure</button>
                                    </div>
                                </div>
                            </div>

                            <hr></hr>
                        </div>


                    </div>
                    <div className="col-lg-12 mt-5">
                        <div className="container">
                            <div className="text-center">
                                <h2>Program
                                    Highlights</h2>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-5">
                        <div class="container">
                            <div class="row">

                                <div class="col-lg-4 mb-4">
                                    <div class="card" style={{ height: "350px" }}>
                                        <div class="card-body">
                                            <h5 class="card-title">Practical Learning and Experience</h5>
                                            <p class="card-text">Strong focus on industry applications of business frameworks and concepts taught.</p>
                                            <p class="card-text">Industry experts and senior management from top companies teach part of every course to impart relevant and practical knowledge and tools.</p>
                                            <p class="card-text">Industry projects and case studies are given throughout the program.</p>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-lg-4 mb-4">
                                    <div class="card " style={{ height: "350px" }}>
                                        <div class="card-body">
                                            <h5 class="card-title">International Business Exposure</h5>
                                            <p class="card-text">The MBA curriculum covers various aspects of business through a global lens. Projects are based on international business scenarios.</p>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-lg-4 mb-4">
                                    <div class="card " style={{ height: "350px" }}>
                                        <div class="card-body">
                                            <h5 class="card-title">Dual Credentials & Alumni Status</h5>
                                            <p class="card-text">Get an MBA from Liverpool Business School and an Advanced General Management certification from IMT Ghaziabad.</p>
                                            <p class="card-text">Gain alumni status from Liverpool Business School and associate alumni status from IMT Ghaziabad.</p>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-lg-4 mb-4">
                                    <div class="card " style={{ height: "350px" }}>
                                        <div class="card-body">
                                            <h5 class="card-title">Customized Curriculum</h5>
                                            <p class="card-text">Choose from a range of elective courses to match your interests and career goals.</p>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-lg-4 mb-4">
                                    <div class="card " style={{ height: "350px" }}>
                                        <div class="card-body">
                                            <h5 class="card-title">1/10th the Cost</h5>
                                            <p class="card-text">An online MBA would cost you 1/10th the price of any full-time MBA from a foreign university. Save time and money by doing an online MBA without leaving your job.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 mb-4">
                                    <div class="card " style={{ height: "350px" }}>
                                        <div class="card-body">
                                            <h5 class="card-title">123admissions Career Support</h5>
                                            <p class="card-text">Get 360-degree career support including industry mentorship, career coaching, career portal access.</p>
                                            <p class="card-text">Attend offline job fairs and networking events in key cities every few months.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-12 mt-5">
                        <div className="eligibility">
                            <div className="row">

                                <div className="col-lg-6">
                                    <div style={{ padding: "10%" }}>
                                        <img src={Assets?.Liverpoolel} style={{ width: "100%" }}></img>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="col-12 mt-5">
                                        <h2>Eligibility</h2>
                                        <p>Bachelor’s degree issued by any university under UGC with a minimum of 50% marks.</p>
                                        <p>At least 2 years of full-time work experience by the second year of the course.</p>
                                    </div>
                                    <div className="col-12 mt-5">
                                        <h4>Application Process</h4>

                                        <h5>Step 1: Complete Application</h5>
                                        <p>The candidates will be evaluated based on their profile, including professional experience and academic scores.</p>

                                        <h5>Step 2: Get Shortlisted and Receive the Offer Letter</h5>
                                        <p>Upon receiving the offer letter, block your seat by paying a caution amount and then pay the balance fee.</p>
                                        <h5>Step 3: Block Your Seat</h5>
                                        <p>After receiving the offer letter, secure your seat by paying a caution deposit and then complete the balance payment.</p>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="col-lg-12 mt-5 liverpoolcity">
                        <div className="container" style={{ padding: "0px 15%" }}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="day-text">On-Campus Immersion</h2>
                                </div>
                                <div class="mb-4 col-lg-6 mt-5">
                                    <div className="days">
                                        <h3>Day 1 - Arrive in the United Kingdom</h3>
                                        <ul>
                                            <li>Arrive at London / Liverpool / Manchester airport</li>
                                            <li>Accommodation at Liverpool Hotel, student accommodation, or apartment</li>
                                            <li>Overnight stay at hotel</li>
                                        </ul>
                                    </div>
                                </div>


                                <div class="col-lg-6 mb-4 mt-5">
                                    <div className="days">
                                        <h3>Day 2 - Introducing Liverpool, the Future of Management, and your MBA</h3>
                                        <ul>
                                            <li>Breakfast will be arranged</li>
                                            <li>Introduction to university and impact theme</li>
                                            <li>Contribution entitled "Futuring"</li>
                                            <li>Free time</li>
                                        </ul>
                                    </div>
                                </div>


                                <div class="col-lg-6 mb-4">
                                    <div className="days">
                                        <h3>Day 3 - Thought Leadership from LBS Professors and Research Academics</h3>
                                        <ul>
                                            <li>Breakfast will be arranged</li>
                                            <li>Topics include intercultural competence and global leadership</li>
                                            <li>Masterclass - Firm Growth and Resilience Post-Pandemic</li>
                                            <li>Impact workshop</li>

                                        </ul>
                                    </div>
                                </div>


                                <div class="col-lg-6 mb-4">
                                    <div className="days">
                                        <h3>Day 4 - Workshops and Felicitation Ceremony</h3>
                                        <ul>
                                            <li>Breakfast will be arranged</li>
                                            <li>Leadership Impact Workshop</li>
                                            <li>Set up in student life building</li>
                                            <li>Felicitation ceremony (immersion participation certificate)</li>
                                            <li>Dinner and closing speeches at campus</li>
                                        </ul>
                                    </div>
                                </div>


                                <div class="col-lg-6 mb-4">
                                    <div className="days">
                                        <h3>Day 5 - Manchester City Tour</h3>
                                        <ul>
                                            <li>Breakfast will be arranged</li>
                                            <li>Visit to Manchester city</li>
                                        </ul>
                                    </div>
                                </div>


                                <div class="col-lg-6 mb-4">
                                    <div className="days">
                                        <h3>Day 6 - Departure and Career Development Bonus</h3>
                                        <ul>
                                            <li>Breakfast and check-out at standard check-out time</li>
                                            <li>Optional extension of trip at personal expense</li>
                                            <li>Bonus: 2-hour session on career development and job opportunities in the UK</li>
                                        </ul>
                                    </div>
                                </div>
                                {/* <div className="col-lg-12">
                                    <p class="text-muted text-center"><em>*Please note: This itinerary is tentative and may change based on activity availability and weather conditions.</em></p>
                                </div> */}
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-12 mt-5">
                        <div className="container">
                            <div className="text-center">
                                <h2>Hear from our happy Students:</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-5">
                        <div className="review">
                            <div className="row">
                                <div className="col-lg-6 mt-3">
                                    <div className="s_review">
                                        <div className="s_review_h">
                                            <div className="cricle">
                                                S
                                            </div>
                                            <div className="star">
                                                <h5>Surendar</h5>
                                                <h5 className="gh">★★★★★</h5>
                                            </div>

                                        </div>
                                        <div className="s_review_s">
                                            <p>123admissions was a game-changer in my journey to higher education. The team provided invaluable guidance and support throughout the application process. Their expertise in understanding top-tier universities and their requirements was instrumental in securing my admission to a prestigious institution. I am truly grateful for their timely assistance and personalized </p>
                                        </div>
                                        <div className="s_review_b">
                                            Dec 1, 2023
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-3">
                                    <div className="s_review">
                                        <div className="s_review_h">
                                            <div className="cricle">
                                                R
                                            </div>
                                            <div className="star">
                                                <h5>Rahul</h5>
                                                <h5 className="gh">★★★★★</h5>
                                            </div>

                                        </div>
                                        <div className="s_review_s">
                                            <p>123admissions was instrumental in my success. Their expert guidance helped me identify the right programme that are highly flexible. Thanks to their support, I secured a prestigious MBA and landed a dream job in healthcare administration. I highly recommend 123admissions to anyone looking to elevate their career.
                                            </p>
                                        </div>
                                        <div className="s_review_b">
                                            Jan 12, 2023
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-3">
                                    <div className="s_review">
                                        <div className="s_review_h">
                                            <div className="cricle">
                                                S
                                            </div>
                                            <div className="star">
                                                <h5>Sathya</h5>
                                                <h5 className="gh">★★★★★</h5>
                                            </div>

                                        </div>
                                        <div className="s_review_s">
                                            <p>123admissions' impressive network of top-tier universities gave me the confidence to choose them for my online college admissions. Thanks to their expert guidance, I successfully secured a spot in a prestigious university's online degree programme</p>
                                        </div>
                                        <div className="s_review_b">
                                            Oct 26, 2022
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-3">
                                    <div className="s_review">
                                        <div className="s_review_h">
                                            <div className="cricle">
                                                DA
                                            </div>
                                            <div className="star">
                                                <h5>DEVI A</h5>
                                                <h5 className="gh">★★★★★</h5>
                                            </div>

                                        </div>
                                        <div className="s_review_s">
                                            <p>As a nurse working in Saudi Arabia, I needed a flexible, online programme to advance my career in hospital and healthcare management.They helped me find top-tier universities that offered affordable programme that fit my busy schedule. Their guidance throughout the entire process was invaluable.</p>
                                        </div>
                                        <div className="s_review_b">
                                            Jan 12, 2020
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-5">
                        <div className="container">
                            <div className="text-center">
                                <h1>Frequently Asked Questions</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-5">
                        <div className="faq-outer">
                            <div className="faq">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="faq-q" onClick={() => toggleSection(0)}>
                                            <h5>What are the institutions partnered with 123Admissions?</h5>
                                            <small>+</small>
                                        </div>
                                        {openSections[0] && (
                                            <div className="faq-a">
                                                <p>123Admissions has partnership with a wide range of renowned institutions, including LIBA, Golden Gate University, Liverpool University, Bharathidasan University, Andhra University, DY Patil University and many more. These partnerships allow students to access high-quality online degree programme.</p>
                                            </div>)}

                                    </div>
                                    <div className="col-lg-12">
                                        <div className="faq-q" onClick={() => toggleSection(1)}>
                                            <h5>What are the courses available with 123Admissions?</h5>
                                            <small>+</small>
                                        </div>
                                        {openSections[1] && (<div className="faq-a">
                                            <p>123Admissions offers a wide range of online degree programme, including Bachelor's degrees and Master's degrees in high-demand specializations. These programme are designed to be accessible and affordable, making them a great option for students seeking flexible and convenient education.</p>
                                        </div>)}
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="faq-q" onClick={() => toggleSection(2)}>
                                            <h5>Are these online degrees really credible ?</h5>
                                            <small>+</small>
                                        </div>
                                        {openSections[2] && (<div className="faq-a">
                                            <p> Yes, The University Grants Commission (UGC) in India has recognized online degrees as equivalent to offline degrees.This means that online degrees from UGC approved universities hold the same value and credibility as traditional on-campus degrees</p>
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-5">
                        <div className="floater">
                            <div className="row">
                                <div className="col-lg-6">
                                    <h1>123 Admissions</h1>
                                    <h3>The Number One Admissions Partner</h3>
                                    <h2>Join a Community of Diverse Learners</h2>
                                </div>
                                <div className="col-lg-6">
                                    <button className="whatapp">Join Us on WhatsApp</button>
                                </div>
                                <div className="col-lg-12 mt-5">
                                    <div className="text-center">
                                        <p>© 2015-2024 123admissions. All rights reserved</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="whatappicon" >
                    <img src={Whatapps} onClick={openWhatsApp}></img>
                </div>
                <div className="call" onClick={openCall}>
                    <FiPhoneCall />
                </div>
            </div>
        </>
    )
}
export default Livepool