import React, { useEffect, useRef, useState } from 'react';
import './ChatBox.css';
import axios from 'axios';
import Assets from '../../assets/assets';
import Logo from '../../assets/Logo/Group 268.svg';
import close from '../../assets/icons8-close-50.png'
import sendicon from '../../assets/Send icon.svg'

const ChatBox = ({ setchatfun }) => {
    const [input, setInput] = useState('');
    const [inputtext, setInputtext] = useState('')
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        name: '',
        number: '',
        email: '',
        working: '',
        counties: ''
    });
    console.log(formData)
    const [sendInput, setSendInput] = useState(true)
    const [inputfield, setInputfield] = useState(true)
    const [summit, setSummit] = useState(true)
    const [summitbot, setSummitbot] = useState(true)
    const [working, setWorking] = useState(true)
    const [workingtext, setworkingtext] = useState(true)
    const [countiesSelect, setcountiesSelect] = useState(true)
    const [countiesSelectlist, setcountiesSelectlist] = useState(true)
    const [countiesSelecttext, setcountiesSelecttext] = useState(true)
    const [universityName, setuniversityName] = useState('')
    const [univercity, setUniversity] = useState([])
    const [courses, setCourses] = useState([])
    const [id, setId] = useState('')
    const [coursename, setcourseName] = useState('')
    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    // input field on change
    console.log('000000000', courses)
    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };
    const FieldSubmit = () => {

    };
    // input field close
    // scroll top 

    useEffect(() => {
        scrollToBottom();
    }, []);
    useEffect(() => {
        fetchData()
    }, [countiesSelecttext])
    useEffect(() => {
        University()
    }, [universityName])
    // scroll end
    // send input 
    const handleInputChange = (e) => {
        setInput(e.target.value);
    };
    const handleSend = () => {
        setInputtext(input)
        scrollToBottom()
        setSendInput(false)
        setInput('')
        setInputfield(false)

    };

    // send input end
    const arrworking = [
        {
            text: "working professional"
        },
        {
            text: "Student"
        },
        {
            text: "Fresher"
        },
        {
            text: "Business Owner"
        },
    ]
    const workingData = (d) => {
        setFormData((prevData) => ({
            ...prevData,
            ['working']: d
        }));
        setWorking(true)
        setworkingtext(false)
        setcountiesSelect(false)
        setcountiesSelectlist(false)
        scrollToBottom()

    }
    const counties = [
        {
            CauseTile: "India",

        },
        {
            CauseTile: "US",

        },
        {
            CauseTile: "Germany",

        },
        {
            CauseTile: "France",

        },
        {
            CauseTile: "Canada",

        },
        {
            CauseTile: "Finland",

        },

    ]
    const SelectCountry = (d) => {
        setFormData((prevData) => ({
            ...prevData,
            ['counties']: d
        }));

        setcountiesSelecttext(false)
        setcountiesSelectlist(true)
        setId()
        scrollToBottom()


    }
    const fetchData = async () => {
        if (formData?.counties != '') {
            try {
                const response = await axios.get(`https://api.123admissions.com/api/v1/getalluniversity?Key=${formData?.counties}&type=1`, {
                });
                setUniversity(response?.data?.data?.university)
                scrollToBottom()

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {

        }
    };
    const validate = () => {
        let errors = {};
        if (!formData.name) errors.name = "Name is required";
        if (!formData.number) errors.contact = "Contact is required";
        if (!formData.email) {
            errors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = "Email address is invalid";
        }
        return errors;
    };
    const handleSubmit = () => {
        const validationErrors = validate();
        setErrors(validationErrors);
        console.log(validationErrors)
        // console.log('kkkkkkkkkkkkkk',Object.keys(validationErrors).length)
        if (Object.keys(validationErrors).length === 0) {
            const data = JSON.stringify({
                contact: formData.number,
                name: formData.name,
                email: formData.email
            });

            const config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://api.123admissions.com/api/v1/studentform',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    setSummit(false)
                    setSummitbot(false)
                    setWorking(false)
                    scrollToBottom();

                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    const University = async () => {
        try {
            const response = await axios.get(`https://api.123admissions.com/api/v1/getuniversity?id=${id}`, {

            });
            setCourses(response?.data?.data?.Courses)
            scrollToBottom()
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const UniversityNamesetting = (d, id) => {
        setuniversityName(d)
        setId(id)
        scrollToBottom()
    }
    return (
        <div className="chat-box" style={{ borderRadius: "10px" }}>
            <div className='row' style={{ backgroundColor: "var(--green1)", padding: "15px", boxShadow: "5px 5px 12px #81818161" }}>
                <div className='col-lg-3'>
                    <img src={Logo} style={{ width: "80%" }}></img>
                </div>
                <div className='col-lg-7'>
                    <div style={{
                        color: "white",
                        fontSize: "30px",
                        fontWeight: "700",

                    }}>
                        EDU BOT
                    </div>
                </div>
                <div className='col-lg-2' onClick={() => setchatfun(false)}>
                    <img src={close} style={{ width: "80%", marginTop: "5px" }}></img>
                </div>
            </div>
            <div className="messages">
                <div className='message bot'>
                    <div className="text">Welcome to 123admissions</div>
                </div>
                {sendInput ? <></> : <div className='message2 user'>
                    <div className='text'>
                        {inputtext}
                    </div>
                </div>}
                {/* / input field */}
                {inputfield ? <></> :
                    <div className="message bot">
                        <div className="text">Please enter your name and email:</div>
                        <input
                            type="text"
                            className="form-control w-100"
                            name="name"
                            placeholder="Enter your name..."
                            value={formData?.name}
                            onChange={handleFormChange}


                        />
                        {errors?.name}
                        <input
                            type="number"
                            className="form-control w-100 mt-3"
                            name='number'
                            placeholder="Enter your number..."
                            value={formData?.number}
                            onChange={handleFormChange}




                        />
                        {errors?.number}
                        <input
                            type="email"
                            className="form-control w-100 mt-3"
                            name='email'
                            placeholder="Enter your email..."
                            value={formData?.email}
                            onChange={handleFormChange}
                        />
                        {errors?.email}
                        <button onClick={()=>handleSubmit()} className="btn btn-primary w-100">Submit</button>
                    </div>}

                {summit ? <></> : <div className='message2 user'>
                    <div className='text'>
                        {formData?.name}
                    </div>
                </div>}
                {summitbot ? <></> : <div className='message bot'>
                    <div className="text">
                        <p>Hey {formData?.name}! Not sure what you are looking for? I can help!</p>
                        <p>Please select your current profile.</p>
                    </div>
                </div>}
                {working ? <></> : <div className='message user2'>
                    <div className='exp'>
                        {arrworking.map((d) => (<div className='item'
                            onClick={() => workingData(d?.text)}
                        >
                            {d?.text}
                        </div>))}

                    </div>

                </div>}
                {workingtext ? <></> : <div className='message2 user'>

                    <div className='text'>
                        {formData?.working}
                    </div>


                </div>}

                {countiesSelect ? <></> : <div className='message bot'>
                    <div className="text">
                        <p>Awesome! {formData?.name}! In which counties you are interested</p>
                        <p>Please select your current country.</p>
                    </div>
                </div>}
                {countiesSelectlist ? <></> : <div className='message user2'>
                    <div className='exp'>
                        {counties?.map((d) => (<div className='item'
                            onClick={() => SelectCountry(d?.CauseTile)}
                        >
                            {d?.CauseTile}
                        </div>))}
                    </div>
                </div>}
                {countiesSelecttext ? <></> : <div className='message2 user'>
                    <div className='text'>
                        {formData?.counties}
                    </div>
                </div>}
                {formData?.counties != '' ? <div className='message bot'>
                    <div className="text">
                        <p>Awesome! {formData?.name}! In which University you are interested</p>
                        <p>Please select your current profile.</p>
                    </div>
                </div> : <></>}
                {universityName == '' ? <> {univercity.length != 0 ? <div className='message user2'>
                    <div className='exp'>
                        {univercity?.map((d) => (<div className='item' onClick={() => UniversityNamesetting(d?.universityName, d?.id)} >
                            {d?.universityName}
                        </div>))}
                    </div>
                </div> : <></>} </> : <></>}

                {universityName != '' ? <div className='message2 user'>
                    <div className='text'>
                        {universityName}
                    </div>
                </div> : <></>}

                {universityName != '' ? <div className='message bot'>
                    <div className="text">
                        <p>Awesome! {formData?.name}! In which course you are interested</p>
                        <p>Please select your current profile.</p>
                    </div>
                </div> : <></>}
                {coursename == '' ? <>{courses?.length != 0 ? <div className='message user2'>
                    <div className='exp'>
                        {courses?.map((d) => (<div className='item' onClick={() => setcourseName(d?.courseName)}>
                            {d?.courseName}
                        </div>))}

                    </div>
                </div> : <></>}</> : <></>}
                {coursename != '' ? <div className='message2 user'>
                    <div className='text'>
                        {coursename}
                    </div>
                </div> : <></>}
                {coursename != '' ? <div className='message bot'>
                    <div className="text">Thanks you for submitting</div>
                    <div className="text">If you have any questions contact 123admissions</div>
                </div> : <></>}
                <div ref={messagesEndRef} />
            </div>
            <div className="input-area">
                <input
                    type="text"
                    value={input}
                    onChange={handleInputChange}

                    placeholder="Type your message..."
                />
                <img onClick={handleSend} src={sendicon}></img>
            </div>
        </div>
    );
};

export default ChatBox;
