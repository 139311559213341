import React, { useEffect, useState } from "react";
import './vit.css'
import Assets from "../../assets/assets";
import Whatapps from '../../assets/bot/whatapp.svg'
import Ig from '../../assets/asssssss.png'
import axios from "axios";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FiPhoneCall } from "react-icons/fi";
import { Helmet } from "react-helmet";

const VIT_admissions = () => {

    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phone: "",
        careerInterest: ""
    });
    const [activeCard, setActiveCard] = useState('card1');
    console.log(activeCard)

    const handleCardClick = (cardId) => {
        // Toggle active state based on cardId
        setActiveCard(cardId);
    };

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");
    const [download, setDownload] = useState(false)

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        // Validate Full Name
        if (!formData.fullName) {
            isValid = false;
            formErrors["fullName"] = "Please enter your full name.";
        }

        // Validate Email
        if (!formData.email) {
            isValid = false;
            formErrors["email"] = "Please enter your email address.";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            isValid = false;
            formErrors["email"] = "Email address is invalid.";
        }

        // Validate Phone Number
        if (!formData.phone) {
            isValid = false;
            formErrors["phone"] = "Please enter your phone number.";
        } else if (!/^\d{10}$/.test(formData.phone)) {
            isValid = false;
            formErrors["phone"] = "Phone number is invalid. Must be 10 digits.";
        }

        // Validate Career Interest
        if (!formData.careerInterest) {
            isValid = false;
            formErrors["careerInterest"] = "Please enter your career interest.";
        }

        setErrors(formErrors);
        return isValid;
    };


    const handleScroll = () => {
        const target = document.getElementById('scrollTarget');
        target.scrollIntoView({ behavior: 'smooth' });
    };

    async function checkForDuplicateLead(email, phone) {
        const webhookUrl = 'https://leadsense.bitrix24.in/rest/31/j87gms00216fmp2o/crm.lead.list.json';
        // Prepare query parameters
        const queryParams = new URLSearchParams();
        if (email) {
            queryParams.append('filter[EMAIL]', email);
        }
        if (phone) {
            queryParams.append('filter[PHONE]', phone);
        }
        queryParams.append('select[]', 'ID'); // Add ID to the select array
        const response = await fetch(`${webhookUrl}?${queryParams.toString()}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        return result.result.length > 0 ? result.result[0].ID : null;
    }

    async function addLeadToBitrix24(name, email, phone, course, soursevalue) {
        const existingLeadId = await checkForDuplicateLead(email, phone);
        // console.log(existingLeadId);
        if (existingLeadId) {
            alert('Your email or phone number is already registered in our system. Our team will be in touch with you shortly..');
            setFormData({
                fullName: "",
                email: "",
                phone: "",
                careerInterest: ""
            })
            setLoading(false);
            return;
        } else {

            const webhookUrl = 'https://leadsense.bitrix24.in/rest/31/j87gms00216fmp2o/crm.lead.add.json';
            const data = {
                fields: {
                    TITLE: 'https://iop.liba.edu/',
                    NAME: name,
                    EMAIL: [{ VALUE: email, VALUE_TYPE: 'WORK' }],
                    PHONE: [{ VALUE: phone, VALUE_TYPE: 'WORK' }],
                    SOURCE_ID: "WEB",
                    UF_CRM_1637687988896: "2361", // Adding source as source description

                }
            };
            try {
                const response = await fetch(webhookUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const result = await response.json();
                if (result.error) {
                    alert('Error: ' + result.error_description);
                } else {
                    // hideLoader();
                    setResponseMessage("Form submitted successfully!");
                    setFormData({
                        fullName: "",
                        email: "",
                        phone: "",
                        careerInterest: ""
                    })
                    setLoading(false);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                alert('Error: ' + error);
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true);

            const url = "https://api.123admissions.com/api/v1/formall";

            let data = new FormData();
            data.append("name", formData.fullName);
            data.append("email", formData.email);
            data.append("contact", formData.phone);
            data.append("cou", formData.careerInterest);
            data.append("id", 'uma');

            try {
                const response = await fetch(url, {
                    method: "POST",
                    body: data
                });

                const result = await response.json();

                if (response.ok) {

                } else {
                    setResponseMessage(`Error: ${result.message}`);
                }

            } catch (error) {
                setResponseMessage("Failed to submit the form. Please try again.");
                console.error("Error:", error);
            } finally {
                // setLoading(false);
                addLeadToBitrix24(formData.fullName, formData?.email, formData.phone)
            }
        }
    };

    const [openSections, setOpenSections] = useState([false, false, false, false]);

    const toggleSection = (index) => {
        setOpenSections(prevSections =>
            prevSections.map((isOpen, i) => (i === index ? !isOpen : isOpen))
        );
    };
    const openWhatsApp = () => {
        const phoneNumber = '919597725388'; // Replace with the phone number you want to open
        const whatsappUrl = `https://wa.me/${phoneNumber}`;
        window.open(whatsappUrl, '_blank');
    };
    const openCall = () => {
        const phoneNumber = '919597725388'; // Replace with the phone number you want to call
        const callUrl = `tel:${phoneNumber}`;
        window.open(callUrl);
    };
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 668, // Adjust this value as per your design for mobile devices
                settings: {
                    slidesToShow: 1, // Number of slides to show on mobile
                },
            },
        ],
    };
    const faqs = [
        {
            question: "Are there any LIVE sessions with the institute faculty in this programme?",
            answer: "This programme includes live sessions with VIT faculty and/or course mentors. (Frequency: One hour/week/subject)"
        },
        {
            question: "What is the role of the Course Mentor? Are they institute faculty?",
            answer: "Course mentors are Industry Practitioners/Subject Matter Experts who help with doubt clearing, cover specific topics in depth, and share real-world examples as needed."
        },
        {
            question: "Is there a qualifying mark/grade to get the final certification in this course?",
            answer: "Participants will be evaluated through a combination of an internal assessment and an end-term examination, with a 30% weightage for the internal assessment and 70% for the end-term examination. Each participant must attain a score of at least 50% in each course/subject to successfully complete the programme. The final assessment test (end-term examination) will be conducted offline at designated centers."
        },
        {
            question: "What will be the mode of the final assessment test (end-term examination)?",
            answer: "The final assessment test (end-term examination) will be conducted offline at designated centers. Currently, VIT has 20 centers across India."
        },
        {
            question: "Who will conduct the doubt clearing sessions?",
            answer: "Doubt clearing sessions are conducted by the Course Mentors via the online learning management system, as they monitor individual student progress."
        },
        {
            question: "Do I get Alumni status of the University on completion of this course?",
            answer: "This programme offers you VIT alumni status, which allows you to network with other VIT alumni and enjoy additional benefits. However, note that these benefits differ from those offered via VIT's offline programmes."
        },
        {
            question: "Can I seek a refund of the programme fees?",
            answer: "We strongly encourage our learners to complete the course to fully grasp the concepts and benefits of the programme. However, if a pause is necessary, a refund may be initiated as per the policy..."
        },
        {
            question: "How long will I have access to the learning materials?",
            answer: "You will have access to the online learning platform, all videos, and programme materials until the end of the programme. Access is restricted to registered participants as per the terms of agreement."
        }
    ];


    // State to track which FAQ is currently open
    const [openIndex, setOpenIndex] = useState(null);
    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    return (
        <>
            <Helmet>
                <title>Liverpool University</title>
                <meta name="description" content="This is the homepage of my React app, showcasing modern web solutions." />
                <meta name="keywords" content="React, Helmet, SEO, JavaScript, Web Development, Frontend" />
                <meta name="author" content="123admissions" />

                {/* Open Graph tags for social media previews */}
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Liverpool University | Home" />
                <meta property="og:description" content="Explore our React app homepage with insights on modern web solutions." />
                <meta property="og:image" content="https://123admissions.com/static/media/liverpoolLogo.3237ad4f1a71e002fa05.webp" />
                <meta property="og:url" content="https://123admissions.com/" />
                <meta property="og:site_name" content="Liverpool University" />

                {/* Twitter Card tags for social media previews */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Liverpool University | Home" />
                <meta name="twitter:description" content="Explore our React app homepage with insights on modern web solutions." />
                <meta name="twitter:image" content="https://123admissions.com/static/media/liverpoolLogo.3237ad4f1a71e002fa05.webp" />
                <meta name="twitter:site" content="@your_twitter_handle" />
                <meta name="twitter:creator" content="@your_twitter_handle" />

                {/* General viewport and SEO tags */}
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="robots" content="index, follow" />
                <meta name="googlebot" content="index, follow" />

                {/* Canonical link */}
                <link rel="canonical" href="https://123admissions.com/" />

                {/* Favicon and Apple Touch Icons */}
                <link rel="icon" href="https://123admissions.com/favicon.ico" type="image/x-icon" />
                <link rel="apple-touch-icon" href="https://123admissions.com/apple-touch-icon.png" sizes="180x180" />
                <link rel="icon" href="https://123admissions.com/favicon-32x32.png" sizes="32x32" type="image/png" />
                <link rel="icon" href="https://123admissions.com/favicon-16x16.png" sizes="16x16" type="image/png" />

                {/* Compatibility and theme color */}
                <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="theme-color" content="#ffffff" />
            </Helmet>
            <div className="vit">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="headers">
                            <h3>Check our latest programme.</h3>
                            <button onClick={handleScroll}>
                                ENQUIRE NOW →
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-5">
                        <div className="bodys">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="pathners">
                                        {/* <img src={Assets.Logo} className="logo" ></img> */}
                                        <img src={Assets.vitlogo} className="logo"></img>
                                    </div>

                                    <h2>Vellore Institute of Technology, Powered by</h2>
                                    <h3>India’s Leading Ed-Tech Platform, 123Admissions</h3>
                                    <small className="fontchange">Your education, Your way - just a click away!</small>

                                    <div className="underline">
                                    </div>
                                    <button>Learn from Anywhere</button>
                                </div>
                                <div className="col-lg-6" >
                                    <div className="campaignimg">
                                        <Slider {...settings}>
                                            <div>
                                                <img src={Assets?.Vitbackground} alt="Slide 1" style={{ width: "100%" }} />
                                            </div>
                                            <div>
                                                <img src={Assets?.vitbackground1} alt="Slide 1" style={{ width: "100%" }} />
                                            </div>
                                            <div>
                                                <img src={Assets?.vitbackground2} alt="Slide 1" style={{ width: "100%" }} />
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 vitbac"  id="scrollTarget">
                        <div className="enquery">
                            <div className="text">
                                <h5>Let's Connect</h5>
                            </div>
                            <div className="form-outer"  >
                                <div className="form">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="text">
                                                    <h5>Kindly fill in the details so our experts can reach out to you.</h5>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 mt-3">
                                                <input
                                                    className="form-control"
                                                    name="fullName"
                                                    value={formData.fullName}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter Your Full Name"
                                                />
                                                {errors.fullName && <small className="text-danger">{errors.fullName}</small>}
                                            </div>
                                            <div className="col-lg-12 mt-3">
                                                <input
                                                    className="form-control"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter Your Email Address"
                                                />
                                                {errors.email && <small className="text-danger">{errors.email}</small>}
                                            </div>
                                            <div className="col-lg-12 mt-3">
                                                <input
                                                    className="form-control"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter Your Phone Number"
                                                />
                                                {errors.phone && <small className="text-danger">{errors.phone}</small>}
                                            </div>
                                            <div className="col-lg-12 mt-3">
                                                <input
                                                    className="form-control"
                                                    name="careerInterest"
                                                    value={formData.careerInterest}
                                                    onChange={handleInputChange}
                                                    placeholder="What would you like to pursue for career growth?"
                                                />
                                                {errors.careerInterest && <small className="text-danger">{errors.careerInterest}</small>}
                                            </div>
                                            <div className="col-lg-12 mt-5">
                                                <div className="text-center">
                                                    <button type="submit" className="btn btn-primary" disabled={loading}>
                                                        {loading ? "Submitting..." : "Enquire Now"}
                                                    </button>
                                                </div>
                                                {responseMessage && <div className="mt-3 text-center">{responseMessage}</div>}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-5 livepoolpara">
                        <div className="container">
                            <h4>About</h4>
                            <p>VIT was established with the aim of providing quality higher education on par with international standards. It persistently seeks and adopts innovative methods to improve the quality of higher education on a consistent basis.The campus has a cosmopolitan atmosphere with students from all corners of the globe. Experienced and learned teachers are strongly encouraged to nurture the students. The global standards set at VIT in the field of teaching and research spur us on in our relentless pursuit of excellence. In fact, it has become a way of life for us. The highly motivated youngsters on the campus are a constant source of pride. Our Memoranda of Understanding with various international universities are our major strength. They provide for an exchange of students and faculty and encourage joint research projects for the mutual benefit of these universities. Many of our students, who pursue their research projects in foreign universities, bring high quality to their work and esteem to India and have done us proud. With steady steps, we continue our march forward. We look forward to meeting you here at VIT.</p>

                        </div>
                    </div>
                    <div className="col-lg-12 mt-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="text-center">
                                        <h2>Courses</h2>
                                    </div>
                                </div>
                                <div className="col-lg-12 mt-4">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div
                                                className={`card ${activeCard === 'card1' ? 'active-card' : ''}`}
                                                onClick={() => handleCardClick('card1')}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <div className="card-body">
                                                    <div className="card-title">
                                                        <img src={Assets?.vitmcd} style={{ width: "100%" }}></img>
                                                        <h5>1. Master of Science in Data Science Programme</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div
                                                className={`card ${activeCard === 'card2' ? 'active-card' : ''}`}
                                                onClick={() => handleCardClick('card2')}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <div className="card-body">
                                                    <div className="card-title">
                                                        <img src={Assets?.vitMCAP} style={{ width: "100%" }}></img>
                                                        <h5>2. Master of Computer Applications (MCA) Programme</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div
                                                className={`card ${activeCard === 'card3' ? 'active-card' : ''}`}
                                                onClick={() => handleCardClick('card3')}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <div className="card-body">
                                                    <div className="card-title">
                                                        <img src={Assets?.vitmap} style={{ width: "100%" }}></img>
                                                        <h5>3. Master of Business Administration (MBA) Programme</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                    {(activeCard == 'card1') ? <div className="col-lg-12 mt-3">
                        <div className="container">

                            <div className="row">
                                <div className="col-lg-12">
                                    <h1>Programme Summary</h1>
                                    <hr></hr>
                                </div>
                                <div className="col-lg-12">
                                    Master of Science in Data Science Programme
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="info-item">
                                            <h5>Institute Name</h5>
                                            <p>Vellore Institute of Technology (VIT)</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="info-item">
                                            <h5>Learning Mode</h5>
                                            <p>Live Online + Recorded Sessions by VIT faculty</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="info-item">
                                            <h5>Programme/ Course Leaders</h5>
                                            <p>Subject Matter Experts / Mid Industry Practitioners</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="info-item">
                                            <h5>Eligibility</h5>
                                            <p>Graduate with minimum 50% with minimum 2 years learning of mathematics or statistics; (45% in the case of candidates belonging to a reserved category)</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="info-item">
                                            <h5>Programme Duration:</h5>
                                            <p>24 Months</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="info-item">
                                            <h5>Cost</h5>
                                            <p>INR 1,50,000</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="info-item">
                                            <h5>Payment Options</h5>
                                            <p>Flexible payment options available</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="info-item">
                                            <h5>Certificate</h5>
                                            <p>Upon successful completion of the programme, participants will be awarded with VIT MsDS degree</p>
                                        </div>
                                    </div>
                                </div>



                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="learning-experience mt-5">
                                        <h2 className="text-center">Learning Experience</h2>
                                        <hr></hr>
                                        <ul>
                                            <li><strong>World-class Faculty:</strong> Learn from the globally renowned VIT faculty</li>
                                            <li><strong>Course Mentors:</strong> Get insights and clear doubts with industry practitioners/subject matter experts</li>
                                            <li><strong>Course Structure:</strong> Industry 4.0 curriculum with diverse electives</li>
                                            <li><strong>Application-based Learning:</strong> Application-based learning with industry-relevant tools</li>
                                            <li><strong>Live Sessions:</strong> Live online sessions by VIT Faculty + Doubt clearing sessions</li>
                                        </ul>


                                    </div>

                                </div>
                            </div>




                        </div>
                        <div className="row mt-5" style={{ backgroundColor: "#1ea698" }}>
                            <div className="col-lg-12">
                                <div className="container mt-5">
                                    <h1 style={{ color: "white" }}>Why Choose a Career in Data Science?</h1>
                                    <p style={{ color: "white", marginTop: "20px" }}>As data is the new capital of the global economy, data science can help create renewed growth,
                                        a stronger performance and more meaningful engagement at work. Having data and analytics at
                                        the centre of your work will ensure that you manage core business operational costs effectively,
                                        make the best decisions and excel in your job.</p>
                                </div>

                            </div>
                            <div className="col-lg-12" style={{ textAlign: "center", padding: "20px" }}>
                                <img src={Ig} style={{ width: "60%", borderRadius: "10px" }}></img>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div class="container mt-5">
                                    <div class="row">
                                        <div className="col-lg-6 mt-5">
                                            <div className="container">
                                                <h4>Credentials</h4>
                                                <p>On successful completion of the programme, participants will be awarded the M.Sc. DS
                                                    degree.
                                                    Participants will be evaluated through a combination of internal assessment and an endterm examination, with a 30% weightage given to the internal assessment and a 70%
                                                    weightage assigned to the end-of-term examination. Each participant must achieve a
                                                    score of at least 50% in each course/subject.
                                                    The Final Assessment Test (End term examination) will be conducted in centers in
                                                    offline mode. Currently, VIT has 20 centers across India. VIT center list will be
                                                    expanded.</p>
                                            </div>


                                        </div>
                                        <div className="col-lg-6 mt-5">
                                            <div className="text-center">
                                                <img src={Assets?.vitcertificate}></img>
                                            </div>
                                        </div>

                                    </div>
                                    <hr></hr>
                                    <div class="row">
                                        <div class="col-md-12 mt-5">
                                            <h3>Programme Modules</h3>
                                        </div>

                                        <div class="col-md-4">
                                            <h4>Year One Learning</h4>

                                            <h5>Semester I</h5>
                                            <ul>
                                                <li>Linear Algebra</li>
                                                <li>Probability and Distribution Models</li>
                                                <li>Exploratory Data Analysis</li>
                                                <li>Data Structures and Algorithms</li>
                                                <li>Python Programming</li>
                                            </ul>


                                            <h5>Semester II</h5>
                                            <ul>
                                                <li>Forecasting and Predictive Analytics</li>
                                                <li>Statistical Inference</li>
                                                <li>Data Base Management Systems</li>
                                                <li>Data Mining</li>
                                                <li>Artificial Intelligence</li>
                                            </ul>
                                        </div>

                                        <div class="col-md-4">
                                            <h4>Year Two Learning</h4>


                                            <h5>Semester III</h5>
                                            <ul>
                                                <li>Elective I</li>
                                                <li>Elective II</li>
                                                <li>Elective III</li>
                                                <li>Elective IV</li>
                                                <li>Elective V</li>
                                            </ul>
                                            <h5>Semester IV</h5>
                                            <ul>
                                                <li>Elective VI</li>
                                                <li>Elective VII</li>
                                                <li>Project Work</li>
                                            </ul>
                                        </div>
                                        <div class="col-md-4">
                                            <h4>Elective Courses</h4>
                                            <ul>
                                                <li>Big Data Analytics</li>
                                                <li>Multivariate Data Analysis</li>
                                                <li>Machine Learning</li>
                                                <li>Programming in Java</li>
                                                <li>Econometric Analysis</li>
                                                <li>Web and Social Media Analytics</li>
                                                <li>Financial Data Analytics</li>
                                                <li>Risk Analytics</li>
                                                <li>Supply Chain Analytics</li>
                                                <li>Marketing Analytics</li>
                                            </ul>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h3 className="mt-5 text-center">FAQs</h3>
                                    <hr></hr>
                                    <div className="faqs mt-5">
                                        {faqs.map((faq, index) => (
                                            <div className="faq-item" key={index}>
                                                <h4 onClick={() => toggleFAQ(index)} style={{ cursor: "pointer" }}>
                                                    {faq.question}
                                                </h4>
                                                {openIndex === index && (
                                                    <p>{faq.answer}</p>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : (activeCard == 'card2') ? <div className="col-lg-12 mt-3">
                        <div className="container">

                            <div className="row">
                                <div className="col-lg-12">
                                    <h1>Programme Summary</h1>
                                    <hr></hr>
                                </div>
                                <div className="col-lg-12">
                                    Master of Science in Data Science Programme
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="info-item">
                                            <h5>Institute Name</h5>
                                            <p>Vellore Institute of Technology (VIT)</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="info-item">
                                            <h5>Learning Mode</h5>
                                            <p>Live Online + Recorded Sessions by VIT faculty</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="info-item">
                                            <h5>Programme/ Course Leaders</h5>
                                            <p>Subject Matter Experts / Mid Industry Practitioners</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="info-item">
                                            <h5>Eligibility</h5>
                                            <p>Graduate with minimum 50% with minimum 2 years learning of mathematics or statistics; (45% in the case of candidates belonging to a reserved category)</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="info-item">
                                            <h5>Programme Duration:</h5>
                                            <p>24 Months</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="info-item">
                                            <h5>Cost</h5>
                                            <p>INR 1,50,000</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="info-item">
                                            <h5>Payment Options</h5>
                                            <p>Flexible payment options available</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="info-item">
                                            <h5>Certificate</h5>
                                            <p>Upon successful completion of the programme, participants will be awarded with VIT MsDS degree</p>
                                        </div>
                                    </div>
                                </div>



                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="learning-experience mt-5">
                                        <h2 className="text-center">Learning Experience</h2>
                                        <hr></hr>
                                        <ul>
                                            <li><strong>World-class Faculty:</strong> Learn from the globally renowned VIT faculty</li>
                                            <li><strong>Course Mentors:</strong> Get insights and clear doubts with industry practitioners/subject matter experts</li>
                                            <li><strong>Course Structure:</strong> Industry 4.0 curriculum with diverse electives</li>
                                            <li><strong>Application-based Learning:</strong> Application-based learning with industry-relevant tools</li>
                                            <li><strong>Live Sessions:</strong> Live online sessions by VIT Faculty + Doubt clearing sessions</li>
                                        </ul>


                                    </div>

                                </div>
                            </div>




                        </div>
                        <div className="row mt-5" style={{ backgroundColor: "#1ea698" }}>
                            <div className="col-lg-12">
                                <div className="container mt-5">
                                    <h1 style={{ color: "white" }}>Why Choose a Career in Data Science?</h1>
                                    <p style={{ color: "white", marginTop: "20px" }}>As data is the new capital of the global economy, data science can help create renewed growth,
                                        a stronger performance and more meaningful engagement at work. Having data and analytics at
                                        the centre of your work will ensure that you manage core business operational costs effectively,
                                        make the best decisions and excel in your job.</p>
                                </div>

                            </div>
                            <div className="col-lg-12" style={{ textAlign: "center", padding: "20px" }}>
                                <img src={Ig} style={{ width: "60%", borderRadius: "10px" }}></img>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div class="container mt-5">
                                    <div class="row">
                                        <div className="col-lg-6 mt-5">
                                            <div className="container">
                                                <h4>Credentials</h4>
                                                <p>On successful completion of the programme, participants will be awarded the M.Sc. DS
                                                    degree.
                                                    Participants will be evaluated through a combination of internal assessment and an endterm examination, with a 30% weightage given to the internal assessment and a 70%
                                                    weightage assigned to the end-of-term examination. Each participant must achieve a
                                                    score of at least 50% in each course/subject.
                                                    The Final Assessment Test (End term examination) will be conducted in centers in
                                                    offline mode. Currently, VIT has 20 centers across India. VIT center list will be
                                                    expanded.</p>
                                            </div>


                                        </div>
                                        <div className="col-lg-6 mt-5">
                                            <div className="text-center">
                                                <img src={Assets?.vitcertificate}></img>
                                            </div>
                                        </div>

                                    </div>
                                    <hr></hr>
                                    <div class="row">
                                        <div class="col-md-12 mt-5">
                                            <h3>Programme Modules</h3>
                                        </div>

                                        <div class="col-md-4">
                                            <h4>Year One Learning</h4>

                                            <h5>Semester I</h5>
                                            <ul>
                                                <li>Linear Algebra</li>
                                                <li>Probability and Distribution Models</li>
                                                <li>Exploratory Data Analysis</li>
                                                <li>Data Structures and Algorithms</li>
                                                <li>Python Programming</li>
                                            </ul>


                                            <h5>Semester II</h5>
                                            <ul>
                                                <li>Forecasting and Predictive Analytics</li>
                                                <li>Statistical Inference</li>
                                                <li>Data Base Management Systems</li>
                                                <li>Data Mining</li>
                                                <li>Artificial Intelligence</li>
                                            </ul>
                                        </div>

                                        <div class="col-md-4">
                                            <h4>Year Two Learning</h4>


                                            <h5>Semester III</h5>
                                            <ul>
                                                <li>Elective I</li>
                                                <li>Elective II</li>
                                                <li>Elective III</li>
                                                <li>Elective IV</li>
                                                <li>Elective V</li>
                                            </ul>
                                            <h5>Semester IV</h5>
                                            <ul>
                                                <li>Elective VI</li>
                                                <li>Elective VII</li>
                                                <li>Project Work</li>
                                            </ul>
                                        </div>
                                        <div class="col-md-4">
                                            <h4>Elective Courses</h4>
                                            <ul>
                                                <li>Big Data Analytics</li>
                                                <li>Multivariate Data Analysis</li>
                                                <li>Machine Learning</li>
                                                <li>Programming in Java</li>
                                                <li>Econometric Analysis</li>
                                                <li>Web and Social Media Analytics</li>
                                                <li>Financial Data Analytics</li>
                                                <li>Risk Analytics</li>
                                                <li>Supply Chain Analytics</li>
                                                <li>Marketing Analytics</li>
                                            </ul>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h3 className="mt-5 text-center">FAQs</h3>
                                    <hr></hr>
                                    <div className="faqs mt-5">
                                        {faqs.map((faq, index) => (
                                            <div className="faq-item" key={index}>
                                                <h4 onClick={() => toggleFAQ(index)} style={{ cursor: "pointer" }}>
                                                    {faq.question}
                                                </h4>
                                                {openIndex === index && (
                                                    <p>{faq.answer}</p>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : (activeCard == 'card3') ? <div className="col-lg-12 mt-3">
                        <div className="container">

                            <div className="row">
                                <div className="col-lg-12">
                                    <h1>Programme Summary</h1>
                                    <hr></hr>
                                </div>
                                <div className="col-lg-12">
                                    Master of Science in Data Science Programme
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="info-item">
                                            <h5>Institute Name</h5>
                                            <p>Vellore Institute of Technology (VIT)</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="info-item">
                                            <h5>Learning Mode</h5>
                                            <p>Live Online + Recorded Sessions by VIT faculty</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="info-item">
                                            <h5>Programme/ Course Leaders</h5>
                                            <p>Subject Matter Experts / Mid Industry Practitioners</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="info-item">
                                            <h5>Eligibility</h5>
                                            <p>Graduate with minimum 50% with minimum 2 years learning of mathematics or statistics; (45% in the case of candidates belonging to a reserved category)</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="info-item">
                                            <h5>Programme Duration:</h5>
                                            <p>24 Months</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="info-item">
                                            <h5>Cost</h5>
                                            <p>INR 1,50,000</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="info-item">
                                            <h5>Payment Options</h5>
                                            <p>Flexible payment options available</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="info-item">
                                            <h5>Certificate</h5>
                                            <p>Upon successful completion of the programme, participants will be awarded with VIT MsDS degree</p>
                                        </div>
                                    </div>
                                </div>



                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="learning-experience mt-5">
                                        <h2 className="text-center">Learning Experience</h2>
                                        <hr></hr>
                                        <ul>
                                            <li><strong>World-class Faculty:</strong> Learn from the globally renowned VIT faculty</li>
                                            <li><strong>Course Mentors:</strong> Get insights and clear doubts with industry practitioners/subject matter experts</li>
                                            <li><strong>Course Structure:</strong> Industry 4.0 curriculum with diverse electives</li>
                                            <li><strong>Application-based Learning:</strong> Application-based learning with industry-relevant tools</li>
                                            <li><strong>Live Sessions:</strong> Live online sessions by VIT Faculty + Doubt clearing sessions</li>
                                        </ul>


                                    </div>

                                </div>
                            </div>




                        </div>
                        <div className="row mt-5" style={{ backgroundColor: "#1ea698" }}>
                            <div className="col-lg-12">
                                <div className="container mt-5">
                                    <h1 style={{ color: "white" }}>Why Choose a Career in Data Science?</h1>
                                    <p style={{ color: "white", marginTop: "20px" }}>As data is the new capital of the global economy, data science can help create renewed growth,
                                        a stronger performance and more meaningful engagement at work. Having data and analytics at
                                        the centre of your work will ensure that you manage core business operational costs effectively,
                                        make the best decisions and excel in your job.</p>
                                </div>

                            </div>
                            <div className="col-lg-12" style={{ textAlign: "center", padding: "20px" }}>
                                <img src={Ig} style={{ width: "60%", borderRadius: "10px" }}></img>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div class="container mt-5">
                                    <div class="row">
                                        <div className="col-lg-6 mt-5">
                                            <div className="container">
                                                <h4>Credentials</h4>
                                                <p>On successful completion of the programme, participants will be awarded the M.Sc. DS
                                                    degree.
                                                    Participants will be evaluated through a combination of internal assessment and an endterm examination, with a 30% weightage given to the internal assessment and a 70%
                                                    weightage assigned to the end-of-term examination. Each participant must achieve a
                                                    score of at least 50% in each course/subject.
                                                    The Final Assessment Test (End term examination) will be conducted in centers in
                                                    offline mode. Currently, VIT has 20 centers across India. VIT center list will be
                                                    expanded.</p>
                                            </div>


                                        </div>
                                        <div className="col-lg-6 mt-5">
                                            <div className="text-center">
                                                <img src={Assets?.vitcertificate}></img>
                                            </div>
                                        </div>

                                    </div>
                                    <hr></hr>
                                    <div class="row">
                                        <div class="col-md-12 mt-5">
                                            <h3>Programme Modules</h3>
                                        </div>

                                        <div class="col-md-4">
                                            <h4>Year One Learning</h4>

                                            <h5>Semester I</h5>
                                            <ul>
                                                <li>Linear Algebra</li>
                                                <li>Probability and Distribution Models</li>
                                                <li>Exploratory Data Analysis</li>
                                                <li>Data Structures and Algorithms</li>
                                                <li>Python Programming</li>
                                            </ul>


                                            <h5>Semester II</h5>
                                            <ul>
                                                <li>Forecasting and Predictive Analytics</li>
                                                <li>Statistical Inference</li>
                                                <li>Data Base Management Systems</li>
                                                <li>Data Mining</li>
                                                <li>Artificial Intelligence</li>
                                            </ul>
                                        </div>

                                        <div class="col-md-4">
                                            <h4>Year Two Learning</h4>


                                            <h5>Semester III</h5>
                                            <ul>
                                                <li>Elective I</li>
                                                <li>Elective II</li>
                                                <li>Elective III</li>
                                                <li>Elective IV</li>
                                                <li>Elective V</li>
                                            </ul>
                                            <h5>Semester IV</h5>
                                            <ul>
                                                <li>Elective VI</li>
                                                <li>Elective VII</li>
                                                <li>Project Work</li>
                                            </ul>
                                        </div>
                                        <div class="col-md-4">
                                            <h4>Elective Courses</h4>
                                            <ul>
                                                <li>Big Data Analytics</li>
                                                <li>Multivariate Data Analysis</li>
                                                <li>Machine Learning</li>
                                                <li>Programming in Java</li>
                                                <li>Econometric Analysis</li>
                                                <li>Web and Social Media Analytics</li>
                                                <li>Financial Data Analytics</li>
                                                <li>Risk Analytics</li>
                                                <li>Supply Chain Analytics</li>
                                                <li>Marketing Analytics</li>
                                            </ul>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h3 className="mt-5 text-center">FAQs</h3>
                                    <hr></hr>
                                    <div className="faqs mt-5">
                                        {faqs.map((faq, index) => (
                                            <div className="faq-item" key={index}>
                                                <h4 onClick={() => toggleFAQ(index)} style={{ cursor: "pointer" }}>
                                                    {faq.question}
                                                </h4>
                                                {openIndex === index && (
                                                    <p>{faq.answer}</p>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : <></>}







                    <div className="col-lg-12 mt-5">
                        <div className="floater">
                            <div className="row">
                                <div className="col-lg-6">
                                    <h1>123 Admissions</h1>
                                    <h3>The Number One Admissions Partner</h3>
                                    <h2>Join a Community of Diverse Learners</h2>
                                </div>
                                <div className="col-lg-6">
                                    <button className="whatapp">Join Us on WhatsApp</button>
                                </div>
                                <div className="col-lg-12 mt-5">
                                    <div className="text-center">
                                        <p>© 2015-2024 123admissions. All rights reserved</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="whatappicon" >
                    <img src={Whatapps} onClick={openWhatsApp}></img>
                </div>
                <div className="call" onClick={openCall}>
                    <FiPhoneCall />
                </div>
            </div >
        </>
    )
}
export default VIT_admissions